/* eslint-env browser */
import singleSpaHtml from 'single-spa-html';

export default singleSpaHtml({
    template: ({ error, singleSpa }) => {
        let details = `- Timestamp: ${new Date().toISOString()}\n`;
        details += `- URL: ${window.location.href}\n`;
        details += `- Awise version: ${window.AWISE_VERSION || 'unknown'}\n`;
        details += `- Error message: ${error.message}\n`;
        details += `- Apps status:\n`;
        singleSpa.getAppNames().forEach((name) => {
            const status = singleSpa.getAppStatus(name);
            if (status !== singleSpa.NOT_LOADED) {
                details += `   ${name}: ${status}\n`;
            }
        });
        details += '- Import map:\n';
        details += JSON.stringify(System.getImportMap().imports, null, 2).replace(/^/gm, '  ');

        const div = document.createElement('div');
        div.innerText = details;
        details = div.innerHTML;

        return `
            <div id="singlespa-loaderror">
                <div>
                    <h3>Oops, houve um erro ao carregar a página 🙁</h3>
                    <div>Por favor, verifique sua conexão com a internet e <a href="">tente novamente</a>.</div>
                    <details>
                        <summary>Ver detalhes para suporte</summary>
                        <pre>${details}</pre>
                    </details>
                </div>
            </div>
        `;
    },
});
